import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp,apiSignInFirebase } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess,onLoginInSuccess, saveDocId } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import deepParseJson from 'utils/deepParseJson'
import { addItem, deleteStateonSignOut, loadFromFirestore, loadStateFromFirebase, loadStateFromFirebaseLogin, sendSharedCourseUsers, setDataAfterLogin } from 'store/tutor/tutorLiveCode'
import { getFirestore, collection,addDoc,doc,updateDoc } from '@firebase/firestore/lite';
import app from 'views/gaze/GazeComponents/firebase'
import { setDocumentId } from 'store/tutor/tutorLiveCode'
import { createUserWithEmailAndPassword } from 'firebase/auth';

import { getAuth, signInWithEmailAndPassword,GoogleAuthProvider } from 'firebase/auth';

import {  toast, Notification,Card } from 'components/ui'

import firebase from 'firebase/app';
import { setSideNavCollapse } from 'store/theme/themeSlice'


const auth = getAuth(app);


function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const db = getFirestore(app);

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const sharedId= useSelector((state) => state.liveCodeSlice.sharedId)
    const documentId=useSelector((state) => state.liveCodeSlice.documentId)

    const userAuthorityTemp = useSelector((state) => state.auth.session.authority)
    const userAuthority = userAuthorityTemp?userAuthorityTemp:'Admin'

    //  const signIn = (email, password, setSuccess) => (dispatch) => {
       
       
       
       
    //     console.log("Email:", email);
    //     console.log("Password:", password);
    //     fire
    //       .auth()
    //       .signInWithEmailAndPassword(email, password)
    //       .then((user) => {
    //         console.log(user);
    //         dispatch(
    //           loginUser({
    //             uid: user.user.uid,
    //             email: user.user.email,
    //             displayName: user.user.displayName,
    //           })
    //         );
    //         setSuccess(true);
    //       })
    //       .catch((error) => {
    //         alert("Invalid email or password!");
    //       });
    //   };

    const signIn = async (values) => {
        try {
            console.log("token======1234 values"+JSON.stringify(values))

            const { email, password } = values;
          //  const resp = await apiSignIn(values)

      
            const resp = await signInWithEmailAndPassword(auth, email, password);
            const user = resp.user;
            console.log('User signed in:', user);
          
       






       //     console.log("token======1234"+resp)
            if (resp.user) {
                const  token  = resp.user
                console.log("token======1234"+JSON.stringify(resp))

             //   dispatch(loadStateFromFirebase(resp.user.uid));
                dispatch(onLoginInSuccess(token))
                if (resp.user) {
                    dispatch(
                        setUser(
                            resp.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                    dispatch(loadStateFromFirebaseLogin(resp.user.uid));
                    
                }

                dispatch(setSideNavCollapse(true));
                const redirectUrl = query.get(REDIRECT_URL_KEY)
             //   console.log("redirect url print==="+redirectUrl)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

//     const signUpInternal = async (values) => {
//         try {

//             console.log("token======1234435"+JSON.stringify(values))
//             const resp = await apiSignUp(values)
           
//             const jsonObject = JSON.parse(JSON.stringify(resp.data));

            
//             const messageId = jsonObject.messageId;
//             const token = jsonObject.messageId;
// // console.log("token======1234messageId"+messageId)
// // console.log("token======1234user"+jsonObject.user)

//             if (jsonObject.user) {
//                const { token,providerData,user } = jsonObject
//             //    console.log("token======1234677789999"+JSON.stringify(providerData))
//              //   console.log("token======1234677789999779798789"+jsonObject)
//           //      console.log("token======1234677789999779798789message"+messageId)
//          //       console.log("token======1234677789999779798789token"+token)
//                 jsonObject.user.messageId = messageId;
//                 jsonObject.user.personalData = JSON.stringify(values);
//               //  jsonObject.personalData=values;
//               //  jsonObject.user.personalData=values;
//               //  jsonObject.user.name = values.userName;
//               //  dispatch(setDocumentId(messageId));
//               //  dispatch(onSignInSuccess(jsonObject.user))
//                 if (jsonObject.user) {

//                     const userToCreate =   {
//                         "id":messageId,
//                         "name":values.name,
//                         "email":jsonObject.user.email,
//                         "img":"/img/avatars/thumb-1.jpg",
//                         "role":values.role,
//                         "lastOnline":1623430400,
//                         "status":"Pending",
//                         "interests":values.interests,
//                         "personalInfo":documentId,
//                         "sharedData":'',
                     
//                       };
//                       console.log("token======1234 Creating user in messages"+JSON.stringify(jsonObject.user))
//                     dispatch(addItem(userToCreate))
//                     // dispatch(
//                     //     setUser(
//                     //         jsonObject.user || {
//                     //             avatar: '',
//                     //             userName: 'Anonymous',
//                     //             authority: ['USER'],
//                     //             email: '',
//                     //         }
//                     //     )
//                     // )


//      //   const db = getFirestore(app);
//         const usersCollection = collection(db, 'messages');
    
//         try{
//             const documentRef = doc(usersCollection,  messageId);
//             updateDoc(documentRef, jsonObject.user);
//            console.log('Document successfully updated!');
//          } catch (error) {
//            console.error('Error updating document:', error);
//          }





//                 }

//                 // toast.push(
//                 //     <Notification title="Signup Success" type="success">
//                 //        Successfully Signed Up,Please login with your created credentials
//                 //     </Notification>,
//                 //     {
//                 //         placement: 'top-center',
//                 //     })
//                 const redirectUrl = query.get(REDIRECT_URL_KEY)

//                 if(sharedId){

                  
                  

//                  //   if(messageId){


//                         // const userToCreate =  [ {
//                         //     "id":messageId,
//                         //     "name":'Shared Learing',
//                         //     "email":'with you',
//                         //     "img":"/img/avatars/thumb-1.jpg",
//                         //     "role":"Admin",
//                         //     "lastOnline":1623430400,
//                         //     "status":"active",
//                         //     "personalInfo":'',
//                         //     "sharedData":sharedId,
                          
//                         //   }];





//                         // dispatch(sendSharedCourseUsers(userToCreate))
//                  //   }
//                 //     navigate(
//                 //        '/app/marketPlaceTutor'
//                 //    )

//                 }else{

//                 //     navigate(
//                 //         redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
//                 //    )

//                 }
               
//                 return {
//                     status: 'success',
//                     message: '',
//                 }
//             }
//         } catch (errors) {
//             return {
//                 status: 'failed',
//                 message: errors?.response?.data?.message || errors.toString(),
//             }
//         }
//     }
const firestore = getFirestore(app);
const signUp = async (values) => {
    try {

     //   console.log("token======1234435"+JSON.stringify(values))
      //  const resp = await apiSignUp(values)
     // const auth = getAuth(app);
        const resp = await createUserWithEmailAndPassword(auth, values.email, values.password);
     //x   console.log('User signed up:', JSON.stringify(resp));
      
        const jsonObject = JSON.parse(JSON.stringify(resp));
     //   const messageId = jsonObject.messageId;
     //   const token = jsonObject.messageId;
// console.log("token======1234messageId"+messageId)
// console.log("token======1234user"+jsonObject.user)

        if (jsonObject.user) {
           const { token,providerData,user } = jsonObject
        //    console.log("token======1234677789999"+JSON.stringify(providerData))
         //   console.log("token======1234677789999779798789"+jsonObject)
      //      console.log("token======1234677789999779798789message"+messageId)
     //       console.log("token======1234677789999779798789token"+token)
          //  jsonObject.user.messageId = messageId;
            jsonObject.user.name = values.userName;
            jsonObject.user.role = values.role;
           // dispatch(setDocumentId(messageId));

         //  dispatch(onLoginInSuccess(token))
            dispatch(onSignInSuccess(jsonObject.user))
            dispatch(setDataAfterLogin(jsonObject.user))
            if (jsonObject.user) {
                dispatch(
                    setUser(
                        jsonObject.user || {
                            avatar: '',
                            userName: 'Anonymous',
                            authority: ['USER'],
                            email: '',
                        }
                    )
                )

             //   console.log("token======1234 Before "+JSON.stringify(jsonObject.user))
 //   const db = getFirestore(app);
  //  const usersCollection = collection(db, 'messages');

    try{
     //   const documentRef = doc(usersCollection,  messageId);
        const documentRef = collection(firestore, 'messages');
        addDoc(documentRef, jsonObject.user).then((docRef) => {
            
            dispatch(setDocumentId(docRef.id));
            dispatch(saveDocId(docRef.id));
            
            
        }

        );
     } catch (error) {
       console.error('Error updating document:', error);
     }





            }

            // toast.push(
            //     <Notification title="Signup Success" type="success">
            //        Successfully Signed Up,Please login with your created credentials
            //     </Notification>,
            //     {
            //         placement: 'top-center',
            //     })
            const redirectUrl = query.get(REDIRECT_URL_KEY)

            if(sharedId){

              
              

             //   if(messageId){


                    // const userToCreate =  [ {
                    //     "id":messageId,
                    //     "name":'Shared Learing',
                    //     "email":'with you',
                    //     "img":"/img/avatars/thumb-1.jpg",
                    //     "role":"Admin",
                    //     "lastOnline":1623430400,
                    //     "status":"active",
                    //     "personalInfo":'',
                    //     "sharedData":sharedId,
                      
                    //   }];





                    // dispatch(sendSharedCourseUsers(userToCreate))
             //   }
                navigate(
                   '/app/marketPlaceTutor'
               )

            }else{

                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
               )

            }
           
            return {
                status: 'success',
                message: '',
            }
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

    const signUpBackUp = async (values) => {
        try {

            console.log("token======1234435"+JSON.stringify(values))
            const resp = await apiSignUp(values)
          
            const jsonObject = JSON.parse(JSON.stringify(resp.data));
            const messageId = jsonObject.messageId;
            const token = jsonObject.messageId;
// console.log("token======1234messageId"+messageId)
// console.log("token======1234user"+jsonObject.user)

            if (jsonObject.user) {
               const { token,providerData,user } = jsonObject
            //    console.log("token======1234677789999"+JSON.stringify(providerData))
             //   console.log("token======1234677789999779798789"+jsonObject)
          //      console.log("token======1234677789999779798789message"+messageId)
         //       console.log("token======1234677789999779798789token"+token)
                jsonObject.user.messageId = messageId;
                jsonObject.user.name = values.userName;
                jsonObject.user.role = values.role;
                dispatch(setDocumentId(messageId));
                dispatch(onSignInSuccess(jsonObject.user))
                dispatch(setDataAfterLogin(jsonObject.user))
                if (jsonObject.user) {
                    dispatch(
                        setUser(
                            jsonObject.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )

                  //  console.log("token======1234 Before "+JSON.stringify(jsonObject.user))
     //   const db = getFirestore(app);
        const usersCollection = collection(db, 'messages');
    
        try{
            const documentRef = doc(usersCollection,  messageId);
            updateDoc(documentRef, jsonObject.user);
           console.log('Document successfully updated!');
         } catch (error) {
           console.error('Error updating document:', error);
         }





                }

                // toast.push(
                //     <Notification title="Signup Success" type="success">
                //        Successfully Signed Up,Please login with your created credentials
                //     </Notification>,
                //     {
                //         placement: 'top-center',
                //     })
                const redirectUrl = query.get(REDIRECT_URL_KEY)

                if(sharedId){

                  
                  

                 //   if(messageId){


                        // const userToCreate =  [ {
                        //     "id":messageId,
                        //     "name":'Shared Learing',
                        //     "email":'with you',
                        //     "img":"/img/avatars/thumb-1.jpg",
                        //     "role":"Admin",
                        //     "lastOnline":1623430400,
                        //     "status":"active",
                        //     "personalInfo":'',
                        //     "sharedData":sharedId,
                          
                        //   }];





                        // dispatch(sendSharedCourseUsers(userToCreate))
                 //   }
                    navigate(
                       '/app/marketPlaceTutor'
                   )

                }else{

                    navigate(
                        redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                   )

                }
               
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(deleteStateonSignOut())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
     //   await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
      //  signUpInternal
    }
}

export default useAuth
