import { THEME_ENUM } from 'constants/theme.constant'

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

// export const themeConfig = {
//     themeColor: 'cyan',
//     direction: THEME_ENUM.DIR_LTR,
//     mode: THEME_ENUM.MODE_LIGHT,
//     primaryColorLevel: 900,
//     cardBordered: false,
//     panelExpand: false,
//     controlSize: 'md',
//    // navMode: "transparent",
//     navMode: THEME_ENUM.NAV_MODE_TRANSPARENT,
//     layout: {
//         type: THEME_ENUM.LAYOUT_TYPE_MODERN,
//         sideNavCollapse: true,
//     },
// }

export const themeConfig={
    "themeColor": "cyan",
    "direction": "ltr",
    "mode": "light",
    "primaryColorLevel": 800,
    "cardBordered": false,
 
    "panelExpand": false,
    "controlSize": "md",
    "navMode": "light",
    "layout": {
      "type": "classic",
      "sideNavCollapse": true
    }
  }