import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'

import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase';
//import {createFirestoreInstance} from 'redux-firestore';
import firebase from 'views/gaze/GazeComponents/firebase'
import createFirestoreInstance from 'redux-firestore/lib/createFirestoreInstance';

import { loadStateFromFirebase,setCourseId } from 'store/tutor/tutorLiveCode'

const environment = process.env.NODE_ENV

//store.dispatch(loadStateFromFirebase());
//store.dispatch(setCourseId());

// const rrfConfig = {
//     userProfile: 'auth', // Specify the collection where user profiles are stored
//     useFirestoreForProfile: true, // Enable Firestore for user profiles
//   };

// const rrfProps = {
//     firebase,
//     config: rrfConfig,
//     dispatch: store.dispatch,
//     createFirestoreInstance,
//   };
//   console.log('firebaseApp:======', firebaseApp.automaticDataCollectionEnabled.valueOf()); 
/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
// if (environment !== 'production' && appConfig.enableMock) {
//     mockServer({ environment })
// }

function App() {
    return (
       
        <Provider store={store}>
             {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
            <PersistGate loading={null} persistor={persistor}>
          
                <BrowserRouter history={history}>
                    <Theme>
                        <Layout />
                    </Theme>
                </BrowserRouter>
              
            </PersistGate>
            {/* </ReactReduxFirebaseProvider> */}
        </Provider>
     
    )
}

export default App
