
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiAskCourseDetails, apiAskUniverse, apiGetAssesment, apiGetPlanForCourse, apiGetQuestionsFromPdf, apiSpeechSynthesis, apiStripPayment, apiStripPaymentCheckoutSession } from 'services/AiBotService'
import ReactMarkdown from 'react-markdown';

import trainingData from 'assets/data/offlineTrainingData/trainingData101.json';

import deepParseJson from 'utils/deepParseJson';
import { act } from 'react-dom/test-utils';
import { getAuth, signInWithEmailAndPassword,GoogleAuthProvider,signInWithPopup } from 'firebase/auth';
import app from 'views/gaze/GazeComponents/firebase'

import store from 'store';
import { loadStateFromFirebaseGoogleLogin } from './tutorLiveCode';
import { onLoginInSuccess } from 'store/auth/sessionSlice';
//import {json} from 'json';
const auth = getAuth(app);
const initialStateStep = {
  currentStep: 2,
  steps: [  {
    stepNumber:1,
    title: "Step 1 Title",
    content: "Description of Step 1",
    completed: false,
    data: {}
  },
  // ... Repeat for steps 2 through 30
  {
    stepNumber:2,
    title: "Step 30 Title",
    content: "Description of Step 30",
    completed: false,
    data: {}
  },
  {
    stepNumber:3,
    title: "Step 30 Title",
    content: "Description of Step 40",
    completed: false,
    data: {}
  }
    // ...initial steps data
  ],
  progress: 0,
  validationErrors: {},
  isCompleted: false
};

export const initialState = {
  id: 1,
  messages: [],
  history: [],
  newHistory: [],
  tutorContext:[],
  tutorStep:"step1",
  evaluetDialogue:false,
  pdfGeneratedQuestions:[],
  //tutorNextStep:"",
  isMe: false,
  takeTest: false,
  stripeData:[],
  newCardAddedIndex: 0,
  extractedData: [],
  updatedHistory: "",
  userStories:[],
  isTutorVisible:false,
  relatedQuestions:[],
  showVideo:false,
  homePageQuestion:[],

  offlineTrainingContent:[],

  userStoryCharStream:[],
  userStoryStringStream:[],
  userStoryContext:[],

  implementCodeStringStream:[],
  implementCodeCharStream:[],
  implementedCodeContext:[],

  projectTutorStringStream:[],
  projectTutorCharStream:[],
  projectTutorContext:[],
  isLoadingSpeech:false,

  taggedQuestion:[],
  taggedQuestionTutor:[],
  previousProjectTutorStringStream:[],
  onlyText:[],

  previousNewHistory:[],
  isStepCreated:false,
  isConnected:false,
  paymentCompleted:false,
  takeTestDialogue:false,
  audioClip:'',

  editorCode:"",
  scrollToTopFlag:false,
  moduleHeader:"step1",
  isLoading :false,
 errorMessage:'',

  stepper: {
      currentStep: 1,
      steps: [
        {
          stepNumber: 1,
          title: "Step 1 Title",
          content: "Description of Step 1",
          completed: false,
          data: {}
        },
        // ... Repeat for steps 2 through 30
        {
          stepNumber: 30,
          title: "Step 30 Title",
          content: "Description of Step 30",
          completed: false,
          data: {}
        }
      ],
      progress: 0,
      validationErrors: {},
      isCompleted: false
    },
  //  ... Other state slices
  

}


const pattern = /(\d+\.\s[\w\s]+):/g;
const regex = /\?/;

const generateRandomId = () => {
  return Math.floor(Math.random() * 1000000);
};




// export const writeFile = (data) => {
//   const jsonString = JSON.stringify(data);
  
//   return json.writeFile('data.json', jsonString)
//     .then(() => {
//       console.log('JSON file has been saved.');
//     })
//     .catch((error) => {
//       console.error('Error writing JSON file:', error);
//     });
// };



export const writeFileSteps = createAsyncThunk('tutor/writeFileSteps', async (data) => {
  console.log("Inside api call" + data);


 const jsonString = JSON.stringify(data);
  
  // const response= json.writeFile('data1.json', jsonString)
  //   .then(() => {
  //     console.log('JSON file has been saved.');
  //      response = true;
  //   })
  //   .catch((error) => {
  //     console.error('Error writing JSON file:', error);
  //     response = false;
  //   });


  //return response
})


export const getChatGPTApiData = createAsyncThunk('tutor/getChatGPTApiData', async (data) => {
  console.log("Inside api call" + data);
//  let response;

 // if(!store.getState().tutor.state.isLoading){
  const   response = await apiAskUniverse(data)
 // }

  return response.data
})






export const getStripePayment = createAsyncThunk('tutor/getStripePayment', async (data) => {


  // if(!store.getState().tutor.state.isLoading){
    const   response = await apiStripPayment(data)
    // }
    console.log("Inside api call" + JSON.stringify(response));
     return response.data
})


// export const getStripePaymentCheckout = createAsyncThunk('tutor/getStripePaymentCheckout', async (data) => {


//   // if(!store.getState().tutor.state.isLoading){
//     const   response = await apiStripPaymentCheckoutSession(data)
//     // const locationHeader = response.headers.get('Location');
//     // // }
//     // console.log("Inside api call" + JSON.stringify(locationHeader));

//      return response.data
// })


//const handleGoogleSignup = async () => {
  export const handleGoogleSignup = createAsyncThunk('tutor/handleGoogleSignup', async (data,{dispatch}) => {
  const provider = new GoogleAuthProvider(auth);
  try {
    const result = await signInWithPopup(auth, provider);
    if (result.user) {
      // Dispatch action for successful login
      dispatch(onLoginInSuccess(result.user));

      // Load state from Firebase for Google login
      dispatch(loadStateFromFirebaseGoogleLogin(result.user));

      // Redirect user after successful login
   //   const redirectUrl = ''; // Set your redirect URL here
   //   navigate(redirectUrl || '/'); // Navigate to the specified URL
    }
  } catch (error) {
    console.error('Google sign-in error:', error);
    // Handle error or display error message
  }
}

)





export const getCourseDetailsApiData = createAsyncThunk('tutor/getCourseDetailsApiData', async (data) => {
  // console.log("Inside api call"+data);

  const response = await apiAskCourseDetails(data)


  return response.data
})

export const getQuestionsFromPdf = createAsyncThunk('tutor/getQuestionsFromPdf', async (data) => {
  // console.log("Inside api call"+data);

  const response = await apiGetQuestionsFromPdf(data)
  console.log("Inside api call" + JSON.stringify(response));

  return response.data.answer
})



export const tutor = createSlice({
  name: 'tutor',
  initialState,
  initialStateStep,
  reducers: {
    sendMessageToChatBox: (state, action) => {
      const responseMessage = {
        id: generateRandomId(),
        message: action.payload,
        isMe: true
      };
     
      state.messages = [...state.messages, responseMessage];
    //  state.isLoading = true;
      state.loadingMessageId = responseMessage.id;
    },
    updateRemovedRow:(state, action) => {

      var row = action.payload;
      console.log("in side remode reducers===="+JSON.stringify(row.original.id))
    //  state.messages = [];
    const updatedData = state.messages.filter((item) => item.id !== row.original.id);
    state.messages = [];
      state.messages = [...state.messages, ...updatedData];
    
    },
    fetchTrainingModule:(state, action) => {

      state.offlineTrainingContent=[];
      const rowToFetch = action.payload;
      state.moduleHeader = rowToFetch;
   //   state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData[rowToFetch]]

     // state.scrollToTopFlag = true;
    
    },
    setTakeTestDialogue: (state,action) => {
      state.takeTestDialogue = action.payload
  },
  setEvaluateDialogue: (state,action) => {
    state.evaluetDialogue = action.payload
},
    setScrollTopFlag:(state, action) => {

    
      state.scrollToTopFlag = action.payload;
    
    },setShowTutorComponent:(state, action) => {

    
      state.isTutorVisible = action.payload;
    
    }
    ,setPdfGeneratedQuestions:(state, action) => {

    
      state.relatedQuestions = action.payload;
    
    },
    setIsLoading:(state, action) => {

    
      state.isLoading = action.payload;
    
    },
  
        nextStep(state) {
          state.currentStep = Math.min(state.currentStep + 1, state.steps.length);
          state.progress = (state.currentStep / state.steps.length) * 100;
        },
        previousStep(state) {
          state.currentStep = Math.max(state.currentStep - 1, 1);
        },
        updateStepData(state, action) {
          const { stepNumber, data } = action.payload;
          const step = state.steps.find(step => step.stepNumber === stepNumber);
          if (step) {
            step.data = data;
          }
        },
        validateStep(state, action) {
          const { stepNumber, errors } = action.payload;
          state.validationErrors[stepNumber] = errors;
          const step = state.steps.find(step => step.stepNumber === stepNumber);
          if (step) {
            step.completed = errors.length === 0;
          }
        },
        completeProcess(state) {
          state.isCompleted = true;
        },
   
    setEditView:(state, action) => {

    
      state.isStepCreated = action.payload;
    
    },
    setEditoCode:(state, action) => {

  //    var row = action.payload;
  //    console.log("in side remode reducers===="+JSON.stringify(row.original.id))
    //  state.messages = [];
 //   const updatedData = state.messages.filter((item) => item.id !== row.original.id);
    state.editorCode = action.payload
    //  state.messages = [...state.messages, ...updatedData];
    
    },
    setStepData:(state, action) => {

   //   var row = action.payload;
    //  console.log("in side remode reducers===="+JSON.stringify(row.original.id))
    //  state.messages = [];
   // cnst updatedData = action.payload;

      

      if(state.tutorStep==="step1"){

        state.offlineTrainingContent = [];
        state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step2]
        state.tutorStep ="step2"

      }else if(state.tutorStep==="step2"){

        state.offlineTrainingContent = [];
        state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step3]
        state.tutorStep ="step3"

      }else if(state.tutorStep==="step3"){

        state.offlineTrainingContent = [];
        state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step3]
        state.tutorStep ="step4"

      }
      else{
     //   state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step1]
      }

      

    
      
    
    },
    setStepDataBack:(state, action) => {

      //   var row = action.payload;
       //  console.log("in side remode reducers===="+JSON.stringify(row.original.id))
       //  state.messages = [];
      // cnst updatedData = action.payload;
   
         
   
         if(state.tutorStep==="step1"){
   
          //  state.offlineTrainingContent = [];
          //  state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step2]
          //  state.tutorStep ="step2"
   
         }else if(state.tutorStep==="step2"){
   
           state.offlineTrainingContent = [];
           state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step3]
           state.tutorStep ="step1"
   
         }else if(state.tutorStep==="step3"){
   
           state.offlineTrainingContent = [];
           state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step3]
           state.tutorStep ="step2"
   
         }
         else if(state.tutorStep==="step4"){
   
          state.offlineTrainingContent = [];
          state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step3]
          state.tutorStep ="step3"
  
        }
         else{
        //   state.offlineTrainingContent = [...state.offlineTrainingContent,...trainingData.step1]
         }
   
         
   
       
         
       
       },
    
    updateHistoryLiveCode: (state, action) => {


      const regex = /(\d+)\.\s*(.*)/g;
      const serialNumbersWithText = [];
      let match;

      while ((match = regex.exec(state.projectTutorStringStream[0]))) {
        const serialNumber = match[1];
        const textPart = match[2];
        serialNumbersWithText.push({ serialNumber, textPart });
      }


      // const responseMessage = {
      //   id: generateRandomId(),
      //   message: action.payload,
      //   isMe: false
      // };
      // state.messages = [...state.messages, responseMessage];
      state.isLoading = false;
      state.isStoryCreated = true;
      state.taggedQuestion = [...state.taggedQuestion,serialNumbersWithText]



      state.projectTutorContext = [...state.projectTutorContext ,...state.projectTutorStringStream]
      state.projectTutorCharStream =[];
      state.projectTutorStringStream = [];

      

  },
    takeTest: (state, action) => {
      state.takeTest = action.payload;
    },
    formatScreen:(state,action)=>{


      // const regex = /(\d+)\.\s*(.*)/g;
      // const serialNumbersWithText = [];
      // let match;

      // while ((match = regex.exec(state.projectTutorStringStream[0]))) {
      //   const serialNumber = match[1];
      //   const textPart = match[2];
      //   serialNumbersWithText.push({ serialNumber, textPart });
      // }

             const regex = /(\d+)\.\s*(.*)/g;
              const serialNumbersWithText = [];
              const onlyText = [];
              const blankText = "";
              let match;
              let lastIndex = 0;
              const projectTutorString = state.projectTutorStringStream[0] || '';

              while ((match = regex.exec(projectTutorString))) {
                const serialNumber = match[1];
                const textPart = match[2];
  
  // Store the non-matching text before the current match
            if (match.index > lastIndex) {
              const nonMatchingText =projectTutorString.substring(lastIndex, match.index);
            //  serialNumbersWithText.push({ nonMatchingText });
         //   const id = generateRandomId();
            const serialNumber = "";
            const textPart = nonMatchingText;
           // serialNumbersWithText.push({ nonMatchingText });
            serialNumbersWithText.push({ serialNumber, textPart });
         //     serialNumbersWithText.push({ generateRandomId, nonMatchingText });
            }

            // Store the current match
          //  const id = generateRandomId();
            serialNumbersWithText.push({  serialNumber, textPart });

            // Update the lastIndex to the end of the current match
            lastIndex = regex.lastIndex;
          }

          // Store the remaining non-matching text after the last match
          if (lastIndex < projectTutorString.length) {
            const nonMatchingText = projectTutorString.substring(lastIndex);
          //  const id = generateRandomId();
            const serialNumber = "";
            const textPart = nonMatchingText;
           // serialNumbersWithText.push({ nonMatchingText });
            serialNumbersWithText.push({ serialNumber, textPart });
          }








      // const responseMessage = {
      //   id: generateRandomId(),
      //   message: action.payload,
      //   isMe: false
      // };
      // state.messages = [...state.messages, responseMessage];
      state.isLoading = false;
      state.isStoryCreated = true;
      state.taggedQuestion = [...state.taggedQuestion,serialNumbersWithText]
    //  state.onlyText = [...state.taggedQuestion,onlyText]


    },
    formatScreenTutor:(state,action)=>{


      // const regex = /(\d+)\.\s*(.*)/g;
      // const serialNumbersWithText = [];
      // let match;

      // while ((match = regex.exec(state.projectTutorStringStream[0]))) {
      //   const serialNumber = match[1];
      //   const textPart = match[2];
      //   serialNumbersWithText.push({ serialNumber, textPart });
      // }

             const regex = /(\d+)\.\s*(.*)/g;
              const serialNumbersWithText = [];
              const onlyText = [];
              const blankText = "";
              let match;
              let lastIndex = 0;
              const newHistoryString = state.newHistory[0] || '';

              while ((match = regex.exec(newHistoryString))) {
                const serialNumber = match[1];
                const textPart = match[2];
  
  // Store the non-matching text before the current match
            if (match.index > lastIndex) {
              const nonMatchingText =newHistoryString.substring(lastIndex, match.index);
            //  serialNumbersWithText.push({ nonMatchingText });
         //   const id = generateRandomId();
            const serialNumber = "";
            const textPart = nonMatchingText;
           // serialNumbersWithText.push({ nonMatchingText });
         //   serialNumbersWithText.push({ serialNumber, '<li>'+ {textPart} +'</li>' });
            serialNumbersWithText.push({ serialNumber, textPart});

          //    serialNumbersWithText.push({ generateRandomId, nonMatchingText });
            }

            // Store the current match
          //  const id = generateRandomId();
            serialNumbersWithText.push({  serialNumber, textPart });
         //   serialNumbersWithText.push({ serialNumber, textPart: `<p>${textPart}</p>` });


            // Update the lastIndex to the end of the current match
            lastIndex = regex.lastIndex;
          }

          // Store the remaining non-matching text after the last match
          if (lastIndex < newHistoryString.length) {
            const nonMatchingText = newHistoryString.substring(lastIndex);
          //  const id = generateRandomId();
            const serialNumber = "";
            const textPart = nonMatchingText;
           // serialNumbersWithText.push({ nonMatchingText });
            serialNumbersWithText.push({ serialNumber, textPart });
          //  serialNumbersWithText.push({ serialNumber, textPart: `<p>${textPart}</p>` });

          }








      // const responseMessage = {
      //   id: generateRandomId(),
      //   message: action.payload,
      //   isMe: false
      // };
      // state.messages = [...state.messages, responseMessage];
      state.isLoading = false;
      state.isStoryCreated = true;
      state.taggedQuestionTutor = [...state.taggedQuestion,serialNumbersWithText]
    //  state.onlyText = [...state.taggedQuestion,onlyText]


    },
    deleteHistoryUserStory: (state, action) => {
      state.userStoryContext = [...state.userStoryContext ,...state.userStoryStringStream]
      state.userStoryStringStream = [];
      state.userStoryCharStream = [];
    },
   
    clearNewHistoryTutor: (state, action) => {

      state.history =[];
      state.newHistory=[];
      //  state.homePageQuestion =[...state.homePageQuestion,{"questionAsked":questionAsked,"questionAnswered":state.newHistory[0]}] ;
      
      },
   
    deleteHistoryimplementCodeStringStream: (state, action) => {

      state.projectTutorContext = [...state.projectTutorContext ,state.implementCodeStringStream];
   //   state.projectTutorStringStream = [...state.projectTutorStringStream ,...state.implementCodeStringStream]
      state.projectTutorStringStream = [];
      state.projectTutorCharStream = [];
      state.previousProjectTutorStringStream = [];
   //   state.implementCodeCharStream = [];
    },
    setMessageAddress: (state, action) => {
      state.messageAddress = action.payload;
    },
    setNewStory: (state, action) => {
      state.isStoryCreated = false;
    },
    showUserStory: (state, action) => {
      state.isStoryCreated = true;
    },
    newCardAdded: (state, action) => {
      state.newCardAddedIndex = action.payload;
    },
    extractedData: (state, action) => {

      const patternNumber = /(\d+\.\s[\w\s]+):/g;
      const matchedCount = (state.newHistory[0].match(patternNumber) || []).length;

      if (matchedCount > 0) {
        // Insert the button into the character stream
        const button = `
                <button
                  onClick={handleClick()}
                >
                  ${"Start"}
                </button>
              `;

        // Find the index where the pattern is detected
        const patternIndex = state.newHistory[0].search(patternNumber);

        // Insert the button at the pattern index
        state.updatedHistory = state.newHistory[0].slice(0, patternIndex) + button + state.newHistory[0].slice(patternIndex);


        // Use the extractedData as needed



      }
    },

    setChatFromStream: (state, action) => {

      //    let message =[responseMessage.message, action.payload];
      // let testArray=[];   
      //  console.log("I
      const responseMessage = {
        id: generateRandomId(),
        message: (action.payload).reduce((accumulator, currentValue) => accumulator + currentValue, ''),
        isMe: false
      };




      //  state.messages = [...prevMessages, responseMessage];

      state.messages = [...state.messages, responseMessage]
      //  state.isLoading = false;
      //   state.isStoryCreated = true;
    },
    messageRecieved: (state, action) => {

    //  console.log("The message is goin to==+"+state.messageAddress);



      if(state.messageAddress !== null && state.messageAddress == "userStory"){

        state.userStoryStringStream = []
        state.userStoryCharStream = [...state.userStoryCharStream, action.payload]
        state.userStoryStringStream = [...state.userStoryStringStream, state.userStoryCharStream.join('')]
        

      }
      else if(state.messageAddress !== null && state.messageAddress == "code"){

        state.implementCodeStringStream = []
        state.implementCodeCharStream = [...state.implementCodeCharStream, action.payload]
        state.implementCodeStringStream = [...state.implementCodeStringStream, state.implementCodeCharStream.join('')]

      }else if(state.messageAddress !== null && state.messageAddress == "tutor"){

      

        state.previousNewHistory[0] =state.newHistory[0]
        state.newHistory = []

       // state.newHistory = []
        state.history = [...state.history, action.payload]
        state.newHistory = [...state.newHistory, state.history.join('')]



        // state.history = [...state.history, action.payload]
        // state.newHistory = [...state.newHistory,( action.payload).join('')]

     


        // const markdownContent = state.newHistory[0] ? (
        //   <ReactMarkdown escapeHtml={true}  className="blog-content">
        //     {/* {history.replace(pattern, ' [$1]("") :')} */}
        //     {state.newHistory[0].replace(pattern, ' [$1](#):')}
        //     {/* {parseDataToArray({history})} */}
        //   </ReactMarkdown>
        // ) : null;


//         const pattern = /"relatedQuestions": \[([^\]]+)\]/;
//      //   const pattern = /\{(?:[^{}]*("relatedQuestions": \[[^\]]+\]))[^{}]*\}/g;
//     //    const pattern = /\{(?:[^{}]*(?:"relatedQuestion"|("relatedQuestions": \[[^\]]+\])))[^{}]*\}/g;
//         const match = state.newHistory[0].match(pattern);
//      //   state.relatedQuestions = [];
//     // let counter =0;

//         if (match ) {
//             const relatedQuestionsArray = match[1];
//             const relatedQuestions = relatedQuestionsArray.split(',').map(question => question.trim().replace(/"/g, ''));
//             state.relatedQuestions = [];
//             state.relatedQuestions = [...state.relatedQuestions, relatedQuestions]
            
//         //    counter = counter +1;
//             console.log(relatedQuestions);
//         } else {
//                 state.relatedQuestions = [];
// }












        // console.log("Realted quewtion object=="+deepParseJson(state.newHistory[0] ));

        // const relatedQuest= deepParseJson(state.newHistory[0] );

        // state.relatedQuestions = [state.relatedQuestions,relatedQuest]
      //  const jsonPattern =' /(?:\{(?:[^{}]*|(?R))*\})|(?:\[(?:[^\[\]]*|(?R))*\])/g';

    //  const newregex = new RegExp(`(.{0,20})${pattern}`, 'g');

  //  const emptyJsonPattern = '\{\s*\}';

        state.messages.forEach((obj) => {
          if (obj.id === state.loadingMessageId) {

           obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
        //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];
          
         //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
          }
        });

     //   state.offlineTrainingContent = [...state.offlineTrainingContent,...state.messages]

      }
      else if(state.messageAddress != null && state.messageAddress == "projectTutor"){

        state.previousProjectTutorStringStream[0] =state.projectTutorStringStream[0]
        state.projectTutorStringStream = []
      
        state.projectTutorCharStream = [...state.projectTutorCharStream, action.payload]
        state.projectTutorStringStream = [...state.projectTutorStringStream, state.projectTutorCharStream.join('')]

      }


     
      
    },
    // messageRecieved: (state, action) => {
    //   console.log("Data from backend==" + action.payload);

    //   if (Array.isArray(action.payload)) {
    //     const pattern = /^\d+\.\s(.*?)(?=\d+\.\s|$)/gs;
    //     const extractedData = action.payload.reduce((accumulator, current) => {
    //       const match = pattern.exec(current.message);

    //       if (match) {
    //         accumulator.push(match[1].trim());
    //       }

    //       return accumulator;
    //     }, []);

    //     state.newHistory = [];
    //     state.history = [...state.history, ...action.payload];
    //     state.newHistory = [...state.newHistory, extractedData];
    //   }
    // },
    messageSent: (state, action) => {
      state.history = [...state.history, action.payload]
    },

    setShowVideoLandingPage: (state, action) => {
      state.showVideo = action.payload;
    },

    webSocketConnected: (state, action) => {
      if (action.payload) {
        state.isConnected = true
      }
    },
    webSocketDisconneted: (state, action) => {
      state.isConnected = false
    },
    webSocketError: (state, action) => {
      state.error = action.payload
    },
    updateHistory(state, action) {
      // state.history =[...state.history,...state.messages]
      state.tutorContext = [...state.tutorContext ,...state.newHistory]
      state.history = [];
      state.newHistory = [];
      state.previousNewHistory = [];
    },


  },
  // sendPromptChatGPT: (state, action) => {
  //         state.messages = action.payload
  //         state.isLoading = action.isLoading

  // //     },
  extraReducers: (builder) => {
    //  console.log("Inside sendPromptChatGPT"+builder);
    builder
      .addCase(getChatGPTApiData.pending, (state) => {
     //   console.log("Inside pending statusssssssssssssssssss" + builder);
        state.isLoading = true;
        state.error = null;
        state.showVideo=false;
        state.errorMessage=''
      })
      .addCase(getChatGPTApiData.fulfilled, (state, action) => {
        // console.log("Inside payload"+JSON.stringify(action));
        // const responseMessage = { 
        //     id: generateRandomId(), 
        //     message: action.payload, 
        //     isMe: false
        //   };
        // state.messages = [...state.messages, responseMessage];
      //  state.isLoading = false;
        state.isStoryCreated = true;
        state.showVideo=false;
        state.errorMessage=''
        state.isLoading = false;
        // state.newHistory=[];
        // state.history=[];
      })
      .addCase(getChatGPTApiData.rejected, (state,action) => {
          // console.log("Inside pending statusssssssssssssssssss" + builder);
         
           state.errorMessage = action.error.message;
           state.isLoading = false;
         })


      // .addCase(getCourseDetailsApiData.pending, (state, action) => {

      //     const responseMessage = { 
      //         id: generateRandomId(), 
      //         message: action.payload, 
      //         isMe: true
      //       };
      //     state.isLoading = true;
      //     state.error = action.error.message;
      //     state.loadingMessageId = responseMessage.id;
      //    })
      .addCase(getCourseDetailsApiData.fulfilled, (state, action) => {
        // const answer = extractData(action.payload,"Ans :")
        console.log("Inside payload" + JSON.stringify(action.payload));

        const regex = /(\d+)\.\s*(.*)/g;
        const serialNumbersWithText = [];
        let match;

        while ((match = regex.exec(action.payload))) {
          const serialNumber = match[1];
          const textPart = match[2];
          serialNumbersWithText.push({ serialNumber, textPart });
        }


        // const responseMessage = {
        //   id: generateRandomId(),
        //   message: action.payload,
        //   isMe: false
        // };
        // state.messages = [...state.messages, responseMessage];
        state.isLoading = false;
        state.isStoryCreated = true;
        state.userStories = [...state.userStories,serialNumbersWithText]
      })
      .addCase(writeFileSteps.fulfilled, (state, action) => {
     
        state.isStepCreated = action.payload;
    //    state.isStoryCreated = true;
     //   state.userStories = [...state.userStories,serialNumbersWithText]
      })
      .addCase(getStripePayment.fulfilled, (state, action) => {
     
        state.paymentCompleted = true;
        state.stripeData=action.payload
    //    state.isStoryCreated = true;
     //   state.userStories = [...state.userStories,serialNumbersWithText]
     
      }).addCase(getQuestionsFromPdf.fulfilled, (state, action) => {

        console.log("Inside payload" + JSON.stringify(action.payload));
     
        state.relatedQuestions =[...state.relatedQuestions,JSON.parse(action.payload)] ;
       
    //    state.isStoryCreated = true;
     //   state.userStories = [...state.userStories,serialNumbersWithText]
      })
      
  },
})

export const { sendPromptChatGPT,formatScreen,updatedData,setStepData,setStepDataBack,fetchTrainingModule,setEditView,
  updateRemovedRow,formatScreenTutor,deleteHistoryimplementCodeStringStream,deleteHistoryUserStory,setScrollTopFlag,setEvaluateDialogue,
  updateHistoryLiveCode,setMessageAddress,updateHistory, newCardAdded, extractedData, showUserStory,setIsLoading,nextStep, previousStep, updateStepData, validateStep, completeProcess ,
  sendMessageToChatBox, takeTest, setNewStory, setChatFromStream, webSocketConnected, webSocketError, setShowTutorComponent,
  webSocketDisconneted, clearNewHistoryTutor,messageRecieved,setPdfGeneratedQuestions, messageSent,setEditoCode,setShowVideoLandingPage,setTakeTestDialogue } = tutor.actions

export default tutor.reducer

