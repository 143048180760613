import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    apiGetCrmCustomers,
    apPutCrmCustomer,
    apiGetCrmCustomersStatistic,
} from 'services/CrmService'
import { userDetailData } from 'assets/data/usersDetail'
import store from 'store'
//import app from 'views/crm/Mail/firebase';




//const firestore = getFirestore(app);

export const getCustomerStatistic = createAsyncThunk(
    'crmCustomers/data/getCustomerStatistic',
    async () => {
        const response = await apiGetCrmCustomersStatistic()
        return response.data
    }
)

// export const getUsersFromFirebase = createAsyncThunk(
//     'liveProject/loadStateFromFirebaseGoogleLogin',
//     async (data, { dispatch }) => {
//       //  dispatch(messageRecievedThreadTutor(data));
  
  
  
  
//       //  const docRef = doc(collection(firestore, 'messages'));p
//       //  console.log('Existing data:', docRef);
//       //   getDocs(docRef)
  
  
  
  
//       //  const docRef = collection(firestore, 'messages','uzl2dWzZzsZBcByPbvfW');
//       // const docRef = collection(firestore, 'messages');
  
//       //  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
//       //  // console.log('persistData12465727656356735675', rawPersistData);
//       //   const persistData = deepParseJson(rawPersistData)
//       //console.log('Existing data:==123455666', data);
//       const uid = data.uid;
//       console.log("Inside loadStateFromFirebaseGoogleLogin" + uid);
//       //  if(uid){
  
  
  
//       //  }else{
//       //   uid=data;
//       //  }
  
  
//       const docRef = query(collection(firestore, 'messages'), where('providerData[0].email', 'array-contains', '@testmail.com'));
//       // console.log('Existing data:', docRef,uid);
//       //  const docSnap = await getDocs(docRef);
//       getDocs(docRef)
//         .then((docSnap) => {
//           if (docSnap) {
//             const existingData = docSnap;
//             console.log('Existing data:===========12324325245245=  docsanp', JSON.stringify(data));
  
//             if (existingData.empty) {
  
//               const db = getFirestore(app);
//               const messageCollection = collection(db, 'messages');
//               const messageObject = deepParseJson(JSON.stringify(data));

//             //  state.createdCourses = [...state.createdCourses, parsedObject]







//               addDoc(messageCollection, messageObject)
//                 .then((docRef) => {
//                   data.messageId = docRef.id;
//                   console.log('Existing data:===========12324325245245=====Document added with ID: ', data.id);
  
//                   messageObject.messageId = docRef.id;
//                   dispatch(setDocumentId(docRef.id));
//                   //  dispatch(onLoginInSuccess(messageObject))
//                   dispatch(
//                     setUser(
//                       messageObject || {
//                         avatar: '',
//                         userName: 'Anonymous',
//                         authority: ['USER'],
//                         email: '',
//                         messageId: ''
//                       }
//                     )
//                   )
  
//                 //  dispatch(loadStateFromFirebase(data.uid));
//                 })
//                 .catch((error) => {
//                   console.error('Error adding document: ', error);
//                 });
  
  
//             }
//             else {
  
//               existingData.forEach((docSnapshot) => {
  
//                 console.log('Existing data:===========12324325245245=  1212', docSnapshot);
  
  
//                 const data = docSnapshot.data();
//                 const documentId = docSnapshot.id; // Get the document ID
//                 console.log('Existing data:===========12324325245245=====111', documentId);
  
//                 // if(messageId === documentId){
//                 //   console.log('Existing data:===========12324325245245=====', JSON.stringify(data));
  
//                 dispatch(setDocumentId(documentId));
//                 if (data.createdCourses) {
  
//                   dispatch(loadFromFirestore(data))
//                 }
  
//                 // }
  
  
//                 //   state = data;
//                 //  dataArray.push(data);
//               });
  
//             }
  
  
  
//           }
//         })
//         .catch((error) => {
//           console.error('Error getting document: ', error);
//         });
  
  
  
  
  
  
  
//       // Perform additional logic here
//       // You can also dispatch other actions if needed
//     }
//   );

export const getCustomers = createAsyncThunk(
    'crmCustomers/data/getCustomers',
    async (params) => {
        const response = store.getState().liveCodeSlice.userList
        return response
    }
)

export const putCustomer = createAsyncThunk(
    'crmCustomers/data/putCustomer',
    async (data) => {
        const response = await apPutCrmCustomer(data)
        return response.data
    }
)

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: '',
    },
}

export const initialFilterData = {
    status: '',
}

const dataSlice = createSlice({
    name: 'crmCustomers/data',
    initialState: {
        loading: false,
        customerList: userDetailData,
        statisticData: {},
        tableData: initialTableData,
        filterData: initialFilterData,
        selectedUsers:[],
        dataToSendPublish:[],
        sharedId:''
    },
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setCustomerList: (state, action) => {
            state.customerList = action.payload
        },
        clearSelectedUsers: (state, action) => {
            state.selectedUsers = [];
        },
        setSharedMessageId: (state, action) => {
            state.sharedId = action.payload;
        },
        setDataToPublish: (state, action) => {
            state.dataToSendPublish = action.payload;
        },
        setFilterData: (state, action) => {
          //  state.filterData = action.payload
            state.selectedUsers.filter((selectedRow) => selectedRow.email === action.payload.email)
        },
        setSelectedUsers: (state, action) => {
            const tempVal=action.payload;
          //  tempVal.sharedData=state.dataToSendPublish;
           // tempVal.personalInfo=store.getState().auth.user;
            

            state.selectedUsers = [...state.selectedUsers,tempVal]
        },
    },
    extraReducers: {
        [getCustomers.fulfilled]: (state, action) => {
            state.customerList = action.payload
           // state.tableData.total = action.payload.total
            state.loading = false
        },
        [getCustomers.pending]: (state) => {
            state.loading = true
        },
        [getCustomerStatistic.pending]: (state) => {
            state.statisticLoading = true
        },
        [getCustomerStatistic.fulfilled]: (state, action) => {
            state.statisticData = action.payload
            state.statisticLoading = false
        },
    },
})

export const { setTableData, setCustomerList,setDataToPublish, setFilterData ,setSelectedUsers,clearSelectedUsers,setSharedMessageId} =
    dataSlice.actions

export default dataSlice.reducer
