import { createSlice } from '@reduxjs/toolkit'
import store from 'store'
import { loadStateFromFirebase } from 'store/tutor/tutorLiveCode'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        signedIn: false,
        authority:[],
        isPaid:false,
        messageId: '',
        projectId:'',
        projectIndex:'',
        
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
            state.authority=action.payload.role
         //   state.messageId = action.payload.messageId
        },
        onLoginInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
          //  store.dispatch(loadStateFromFirebase());
          //  state.messageId = action.payload.messageId
        },
        setUserAuthority: (state, action) => {
           // state.authority = true
            state.authority =action.payload
          //  store.dispatch(loadStateFromFirebase());
          //  state.messageId = action.payload.messageId
        },
        saveDocId: (state, action) => {
            // state.authority = true
             state.messageId =action.payload
           //  store.dispatch(loadStateFromFirebase());
           //  state.messageId = action.payload.messageId
         },
         saveProjectData: (state, action) => {
            // state.authority = true
             state.projectId =action.payload.projectId
             state.projectIndex =action.payload.projectIndex
           //  store.dispatch(loadStateFromFirebase());
           //  state.messageId = action.payload.messageId
         },
       
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.authority=[]
            state.isPaid= false
            
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setPaid: (state, action) => {
            state.isPaid = action.payload
        },
    },
})

export const { onSignInSuccess,saveProjectData, onSignOutSuccess,setPaid, setToken,onLoginInSuccess,setUserAuthority,saveDocId } =
    sessionSlice.actions

export default sessionSlice.reducer
