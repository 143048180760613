import { createSlice } from '@reduxjs/toolkit'

const formSlice = createSlice({
    name: 'form',
    initialState: {
        list: [],
    },
    reducers: {
        addItem1: (state, action) => {
            // state.list = [...state.list, action.payload]
            state.list.push(action.payload);
        },
    },
})

export const { addItem1} = formSlice.actions

export default formSlice.reducer
