import { PERSIST_STORE_NAME } from 'constants/app.constant'
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';


import reduxFirestore from 'redux-firestore';
import firebase from 'views/gaze/GazeComponents/firebase'
import { reactReduxFirebase } from 'react-redux-firebase'
import synchMiddleware from './synchMiddleware'


const middlewares = [synchMiddleware]


const rrfConfig = {
    userProfile: 'users', // Specify the collection where user profiles are stored
    useFirestoreForProfile: true, // Enable Firestore for user profiles
  };


//   firestore().enablePersistence()
//   .catch((error) => {
//     if (error.code === 'failed-precondition') {
//       console.log('Multiple tabs open, persistence can only be enabled in one tab at a time.');
//     } else if (error.code === 'unimplemented') {
//       console.log('The current browser does not support all the features required to enable persistence.');
//     }
//   });


  
// const persistConfig = {
//     key: 'PERSIST_STORE_NAME',
//     keyPrefix: '',
//     storage,
//     whitelist: ['auth', 'theme', 'locale'],
// }

const persistConfig = {
    key: 'root',
    keyPrefix: '',
    storage,
    whitelist: ['auth', 'theme', 'locale'],
}

const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer()),
    //reactReduxFirebase(firebase, rrfConfig)
   // reducer: reactReduxFirebase(firebase, rrfConfig),
   // compose: reduxFirestore(firebaseApp),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(middlewares),
    devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {}

// store.subscribe(() => {
//     const state = store.getState();
//     store.dispatch(synchReduxToFirebase(state));
//   });

export const persistor = persistStore(store)

export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
        return false
    }
    store.asyncReducers[key] = reducer
    store.replaceReducer(
        persistReducer(persistConfig, rootReducer(store.asyncReducers)),
      //  reactReduxFirebase(firebase,rrfConfig),
    )
    persistor.persist()
    return store
}

export default store
