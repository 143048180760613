import ApiService from './ApiService'
import app from 'views/gaze/GazeComponents/firebase'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, getDocs,addDoc,doc,updateDoc } from '@firebase/firestore/lite';


export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/signin',
        method: 'post',
        data,
    })
}


// export async function apiSignInFirebase(data) {

//     console.log("data====123445"+JSON.stringify(data))
//    // const firestore = getFirestore(app);
//     const auth = getAuth(app);
  
//   //  const [email, password] = data;
  
//     auth.signInWithEmailAndPassword
//     .signInWithEmailAndPassword(data.email, data.password);
// }

export async function apiSignUp(data) {
    
    return ApiService.fetchData({
        url: '/gaze_tutor/signup',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/signout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/resetpassword',
        method: 'post',
        data,
    })
}
